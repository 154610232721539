<template>
    <div class="page_section d-flex" v-if="region.bySlug.id">
        <!--<div class="container">
            <h1>{{region.bySlug.title}}</h1>
            <p>Color: {{region.bySlug.color}}</p>
            <div v-html="region.bySlug.description"></div>
            <h2>Content Elements</h2>
            <hr>
            <ContentElements v-for="(element, index) in region.bySlug.content_elements" :data="element" :key="index" />
        </div>-->
        <MobileTitle :title="region.bySlug.title" />
        <ContentElements v-for="(element, index) in region.bySlug.content_elements" :content="element" :key="index" />
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import ContentElements from "../_content_elements/ContentElements";
    import MobileTitle from "../_common/MobileTitle";

    export default {
        name: "Region",
        components: {
            ContentElements,
            MobileTitle
        },
        computed: {
            ...mapState([
                'region',
            ])
        },
        created() {
            if(this.$route.params.slug) {
                this.$store.dispatch('region/getBySlug', this.$route.params.slug).then(() => {}, (error) => {
                    if(error.response.status === 404) {
                        this.$router.replace({
                            name: '404', params: {
                                locale:  this.$route.params.locale ? this.$route.params.locale : null
                            }
                        });
                    }
                });
            }
        },
        beforeDestroy() {
            this.$store.commit('region/setBySlug', {});
        }
    }
</script>

<style scoped>

</style>
